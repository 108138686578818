function loadTextFileAjaxSync(filePath: string, mimeType: string): string {
    const xmlhttp = new XMLHttpRequest();
    xmlhttp.open("GET", filePath, false);
    if (mimeType != null) {
        if (xmlhttp.overrideMimeType) {
            xmlhttp.overrideMimeType(mimeType);
        }
    }
    xmlhttp.send();
    if (xmlhttp.status === 200) {
        return xmlhttp.responseText;
    } else {
        return "";
    }
}

function loadJSON(filePath: string): any {
    const json = loadTextFileAjaxSync(filePath, "application/json");
    const config: any = JSON.parse(json);

    config["production"] = false;
    config["version"] = require("../../package.json").version;
    return config;
}

export const environment = loadJSON("assets/config/config.json");
