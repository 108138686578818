import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HunterTaskPermission } from "../../model/HunterTaskPermission";
import { BaseService } from "./base.service";

@Injectable()
export class TaskPermissionService extends BaseService<HunterTaskPermission> {
    constructor(protected http: HttpClient) {
        super(http, "taskdefpermission");
    }
}
