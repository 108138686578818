import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { HunterPrefix } from "../../model/HunterPrefix";
import { BaseService } from "./base.service";

@Injectable()
export class PrefixService extends BaseService<HunterPrefix> {
    constructor(protected http: HttpClient) {
        super(http, "prefix");
    }

    next(pfx: string, size: number): Observable<HunterPrefix> {
        return this.http.get<HunterPrefix>(`${this.baseURL}/next/${pfx}/${size}`)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    console.log("Error", error);
                    return new Observable<HunterPrefix>();
                })
            );
    }
}
