import { Injectable } from "@angular/core";

@Injectable()
export class LocalStorageService {

    public storeOnLocalStorage(storageKey: string, element: any): void {
    // get array of tasks from local storage
        const array = JSON.parse(sessionStorage.getItem(storageKey)) || [];
        // push new task to array
        array.push(element);
        // insert updated array to local storage
        sessionStorage.setItem(storageKey, JSON.stringify(array));
    }

    public listFromLocalStorage(storageKey: string): any[] {
        return JSON.parse(sessionStorage.getItem(storageKey));
    }

    public removeAllFromLocalStorage(storageKey: string): void {
        sessionStorage.removeItem(storageKey);
    }

    public getFromLocalStorage(storageKey: string, key: string, value: any): any {
        return JSON.parse(sessionStorage.getItem(storageKey)).find((r: any) => r[key] === value) || {};
    }

    public removeFromLocalStorage(storageKey: string, key: string, value: any): void {
        sessionStorage.removeItem(this.getFromLocalStorage(storageKey, key, value));
    }
}
