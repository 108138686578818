import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HunterAddressModel } from "../../model/HunterAddress";
import { BaseService } from "./base.service";

@Injectable()
export class AddressModelService extends BaseService<HunterAddressModel> {
    constructor(protected http: HttpClient) {
        super(http, "addressmodel");
    }
}
