import { NgModule } from "@angular/core";
import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./security/guards/auth.guard";
import { SecurityModule } from "./security/security.module";
import { HomeComponent } from "./_home/home.component";

const ROUTE_OPTIONS: ExtraOptions = {
    onSameUrlNavigation: "reload",
    useHash: true
};

export const routes: Routes = [
    { path: "", redirectTo: "security/login", pathMatch: "full" },
    {
        path: "home",
        component: HomeComponent,
        canActivate: [AuthGuard],
        children: [
            { path: "gis", loadChildren: () => import("./gis/gis.module").then(m => m.GisModule) },
            { path: "yms", loadChildren: () => import("./yms/yms.module").then(m => m.YmsModule) },
            { path: "core", loadChildren: () => import("./core/core.module").then(m => m.CoreModule) },
            { path: "report", loadChildren: () => import("./report/report.module").then(m => m.ReportModule) },
            { path: "process", loadChildren: () => import("./process/process.module").then(m => m.ProcessModule) },
            { path: "custom-ip", loadChildren: () => import("./custom-ip/ip.module").then(m => m.CustomIpModule) },
            { path: "custom-gpa", loadChildren: () => import("./custom-gpa/gpa.module").then(m => m.CustomGPAModule) },
            { path: "custom-solar", loadChildren: () => import("./custom-solar/solar.module").then(m => m.CustomSolarModule) },
            { path: "custom-nestle", loadChildren: () => import("./custom-nestle/nestle.module").then(m => m.CustomNestleModule) },
            { path: "custom-penske", loadChildren: () => import("./custom-penske/penske.module").then(m => m.CustomPenskeModule) },
            { path: "custom-daimler", loadChildren: () => import("./custom-daimler/daimler.module").then(m => m.CustomDaimlerModule) },
            { path: "custom-eurofarma", loadChildren: () => import("./custom-eurofarma/eurofarma.module").then(m => m.CustomEurofarmaModule) },
            { path: "custom-descarpack", loadChildren: () => import("./custom-descarpack/descarpack.module").then(m => m.CustomDescarpackModule) }
        ],
        runGuardsAndResolvers: "always"
    },
    { path: "security", loadChildren: () => import("./security/security.module").then(m => m.SecurityModule) }
];

@NgModule({
    imports: [SecurityModule, RouterModule.forRoot(routes, ROUTE_OPTIONS)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
