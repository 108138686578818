<div class="flex flex-row flex-wrap align-items-start justofy-content-start w-screen">
    <div [ngClass]="{ 'flex flex-column w-2 lg:w-2 md:w-3 sm:w-4 h-screen': url !== '/home' }"
        *ngIf="url !== '/home' && visible && !tokenSvc.kiosk">
        <div class="flex flex-row w-full align-items-center justify-content-center app-logo">
            <img src="assets/imgs/logo_hunter.png" alt="Hunter logo" class="w-3" />
        </div>
        <div class="flex flex-row w-full user-info">
            <user class="w-full"></user>
        </div>
        <div class="flex flex-row w-full permission-list">
            <permission class="w-full"></permission>
        </div>
    </div>
    <div class="flex flex-column" [class.w-screen]="url === '/home' || !visible || tokenSvc.kiosk" [ngClass]="{
            'w-10 lg:w-10 md:w-9 sm:w-8 px-0': url !== '/home' && visible && !tokenSvc.kiosk,
            'px-3': url !== '/home/report/userwidget'
        }">
        <div class="hunter-header" [ngClass]="{'px-3': url === '/home/report/userwidget'}">
            <div class="flex w-1 align-items-start justify-content-start text-black-alpha-50">
                <div *ngIf="!tokenSvc.kiosk" class="cursor-pointer">
                    <i class="pi pi-home pi-fw" (click)="home()"></i>
                </div>
            </div>
            <div class="flex w-9 align-items-start justify-content-center text-black-alpha-40">
                <span class="text-base sm:text-xs md:text-sm">hunter IoT Visibility Manager WEB</span>
                <span *ngIf="sessionSocketConnected"> ®</span>
                <span>{{o.isActivated}}</span>
            </div>
            <div class="flex w-1 align-items-start justify-content-end text-black-alpha-20">
                <span class="text-base sm:text-xs md:text-sm">v{{ version }}{{ !env || env === "" ? "" : " - " + env }}</span>
            </div>
            <div class="flex w-1 align-items-start justify-content-end text-black-alpha-20">
                <span *ngIf="!tokenSvc.kiosk" class="flex signout cursor-pointer">
                    <i class="pi pi-power-off pi-fw" (click)="logout()"></i>
                </span>
            </div>
        </div>
        <div class="hunter-content" [class.on-scrolling]="scrolling" (scroll)="onScroll()">
            <app-dashboard *ngIf="!o.isActivated" class="w-screen"></app-dashboard>
            <div class="hunter-component-content" [@routeAnimations]="getRouterOutletState(o)" [ngClass]="{'hidden': !o.isActivated}">
                <router-outlet #o="outlet"></router-outlet>
            </div>
        </div>
    </div>
</div>