import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LoginComponent } from "./components/login.component";
import { ResetPasswordComponent } from "./components/reset-password.component";

export const routes: Routes = [
    { path: "login", component: LoginComponent },
    { path: "resetPassword/:data", component: ResetPasswordComponent }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SecurityRoutingModule { }
