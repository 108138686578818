import { Component, HostListener, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Spinkit, SpinnerVisibilityService } from "ng-http-loader";
import { MessageService, PrimeNGConfig } from "primeng/api";

@Component({
    selector: "hunter-web",
    templateUrl: "./app.component.html",
    providers: [MessageService]
})
export class AppComponent implements OnInit {
    public spinkit = Spinkit;

    constructor(private spinner: SpinnerVisibilityService, private primengConfig: PrimeNGConfig, private trSvc: TranslateService) {
        // empty
    }

    ngOnInit() {
        this.primengConfig.ripple = true;
        this.trSvc.setDefaultLang("pt");
        this.translate("pt");
    }

    @HostListener("document:keydown.escape", ["$event"])
    handleKeyboardEvent(event: KeyboardEvent) {
        this.spinner.hide();
    }

    translate(lang: string) {
        this.trSvc.use(lang);
        this.trSvc.get("primeng").subscribe(res => this.primengConfig.setTranslation(res));
    }
}
