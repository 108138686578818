import { getLocaleId } from '@angular/common';
import { AfterViewInit, Component, HostListener, Inject, LOCALE_ID, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router, RouterOutlet } from "@angular/router";
import { Message, MessageService } from "primeng/api";
import { Subscription } from "rxjs";
import { environment } from "../../environments/environment";
import { TokenService } from "../security/services/token.service";
import { SocketService } from "../shared/services/socket.service";
import { fadeAnimation, slideInAnimation } from "./components/custom.animations";

@Component({
    animations: [fadeAnimation, slideInAnimation],
    templateUrl: "home.component.html",
    styleUrls: ["home.component.scss"]
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
    private routerSubscription: Subscription;
    private sessionSubscription: Subscription;

    sessionSocketConnected = false;
    url: string;
    visible = true;
    permissions: any[] = [];
    windowWidth: number;
    version: string = environment.version || "unversioned";
    env: string = environment.name;
    scrolling: boolean;
    scrollTimer: NodeJS.Timeout;


    constructor(public tokenSvc: TokenService, private router: Router, private sessionSocket: SocketService, private msgSvc: MessageService) {
        this.routerSubscription = this.router.events.subscribe({
            next: (event) => {
                if (event instanceof NavigationEnd) {
                    this.url = event.url;
                }
            },
            error: (error) => {
                console.log("Navigation Error", error);
            },
            complete: () => {
                console.log("Navigation Complete");
            }
        });
    }

    ngOnInit() {
        this.adjustScreen(window.innerWidth, window.innerHeight);
        // console.log('Home isKiosk', this.tokenSvc.kiosk);
        if (this.tokenSvc.kiosk) {
            this.visible = false;
        }
    }

    ngAfterViewInit(): void {
        const self = this;
        this.sessionSubscription = this.sessionSocket.connect(`${environment.wscore}/user?token=${this.tokenSvc.getToken()}`, {
            onConnect() {
                self.sessionSocketConnected = true;
            },
            onDisconnect() {
                self.sessionSocketConnected = false;
            }
        }, false).subscribe({
            next: (msg: Message) => {
                msg.key = "server-message";
                self.msgSvc.add(msg);
            }
        });
    }

    ngOnDestroy() {
        this.unsubscribeObservables();
    }

    @HostListener("window:beforeunload", ["$event"])
    beforeUnloadHander(event) {
        this.unsubscribeObservables();
    }

    private unsubscribeObservables(): void {
        this.routerSubscription?.unsubscribe();
        this.sessionSubscription?.unsubscribe();
        if (this.tokenSvc.kiosk) {
            this.logout();
        }
    }

    logout() {
        this.tokenSvc.logout();
        this.unsubscribeObservables();
        this.router.navigate(["/security/login"]);
    }

    @HostListener("window:resize", ["$event"])
    onResize(event) {
        // console.log("Adjusting Screen", event);
        this.adjustScreen(event.target.innerWidth, event.target.innerHeight);
    }

    adjustScreen(width: number, height: number): void {
        const actualVH = (height + 9) * 0.01;
        const actualVW = width * 0.01;

        this.windowWidth = width;
        document.documentElement.style.setProperty("--actualVH", `${actualVH}px`);
        document.documentElement.style.setProperty("--actualVW", `${actualVW}px`);
        this.checkMenuVisibility();
    }

    onScroll(): void {
        if (!this.scrolling) {
            this.scrolling = true;
        }
        clearTimeout(this.scrollTimer);
        this.scrollTimer = setTimeout(() => this.scrolling = false, 700);
    }

    checkMenuVisibility() {
        if (this.windowWidth < 1024) {
            this.visible = false;
        } else {
            this.visible = true;
        }
    }

    sideBar() {
        // console.log('Home SideBar');
        this.visible = !this.visible;
    }

    home() {
        this.router.navigate(["/home"]);
    }

    public getRouterOutletState(outlet: RouterOutlet) {
        return outlet.isActivated ? outlet.activatedRoute : "";
    }
}
