import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from "primeng/api";
import { throwError as observableThrowError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ResetPasswordForm } from '../interfaces/ResetPasswordForm';
import { AuthService } from '../services/auth.service';

@Component({
	selector: 'reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

	private resetForm: ResetPasswordForm;
	
	showPass: boolean;
	passwordCorrect: boolean;
	newPassword: string;

	constructor(private authSvc: AuthService, private msgSvc: MessageService,
		private route: ActivatedRoute, private router: Router) {

	}

	ngOnInit(): void {
		this.route.params.subscribe(data => {
			console.log(data.data);
			const str = this.authSvc.decrypt(data.data);
			console.log(str);
			this.resetForm = JSON.parse(str);
		});
	}

	resetPassword() {
		this.resetForm.newPassword = this.newPassword;
		this.authSvc.resetPassword(this.resetForm).pipe(
			catchError((err: HttpErrorResponse) => observableThrowError(err)))
			.subscribe({
				next: (form: ResetPasswordForm) => {
					if (!form?.message) {
						this.msgSvc.add({ severity: "success", summary: "Senha Alterada", detail: "Redirecionando para o hunter" });
						setTimeout(() => {
							this.router.navigate(["home"]);
							console.log('Navigate Home');
						}, 1000);
					} else {
						this.msgSvc.add({ severity: "error", summary: "Senha Não Alterada", detail: form.message });
					}
				},
				error: e => console.log(e)
			});
	}
}