import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HunterTaskDefinition } from "../../model/HunterTaskDefinition";
import { BaseService } from "./base.service";

@Injectable()
export class TaskDefinitionService extends BaseService<HunterTaskDefinition> {
    constructor(protected http: HttpClient) {
        super(http, "taskdef");
    }
}
