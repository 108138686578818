
export default class RestStatus {
    public result: boolean;
    public message: string;

    constructor(res: boolean, msg: string) {
        this.result = res;
        this.message = msg;
    }
}
