import { registerLocaleData } from "@angular/common";
import { HttpClient, HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";
import localePT from "@angular/common/locales/pt";
import { ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgHttpLoaderModule } from "ng-http-loader";
import { MessageService } from "primeng/api";
import { DialogModule } from "primeng/dialog";
import { DividerModule } from "primeng/divider";
import { DockModule } from "primeng/dock";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { PanelModule } from "primeng/panel";
import { PanelMenuModule } from "primeng/panelmenu";
import { RippleModule } from "primeng/ripple";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { TabMenuModule } from "primeng/tabmenu";
import { ToastModule } from "primeng/toast";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { GlobalErrorHandler } from "./error-handler";
import { SecurityModule } from "./security/security.module";
import { DashboardComponent } from "./_home/components/dashboard.component";
import { MenuComponent } from "./_home/components/menu.component";
import { UserComponent } from "./_home/components/user.component";
import { HomeComponent } from "./_home/home.component";

registerLocaleData(localePT);

// required for AOT compilation
export function httpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [AppComponent, HomeComponent, MenuComponent, UserComponent, DashboardComponent],
    imports: [
        AppRoutingModule, BrowserModule, BrowserAnimationsModule, DividerModule, DockModule, DialogModule, FormsModule, HttpClientModule, HttpClientJsonpModule, InputSwitchModule, InputTextModule,
        PanelModule, PanelMenuModule, RippleModule, SecurityModule, ScrollPanelModule, TabMenuModule, ToastModule,
        NgHttpLoaderModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    exports: [],
    providers: [
        MessageService,
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: LOCALE_ID, useValue: "pt" }
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
