<div class="login">
    <div class="flex flex-column">
        <div class="flex align-items-center justify-content-center h-9rem font-bold m-2"></div>
        <div class="flex align-items-center justify-content-center h-3rem font-bold mt-2">
            <img src="assets/imgs/logo_hunter_horizontal.png" class="h-full" alt="Hunter Logo">
        </div>
        <div class="flex align-items-center justify-content-center h-1rem font-bold mb-2">
            <p class="text-black-alpha-20">Technology for Business Transformation</p>
        </div>
        <div class="flex align-items-center justify-content-center h-1rem font-bold m-2"></div>
        <div class="flex align-items-center justify-content-center h-auto font-bold m-2">
            <div class="box shadow-5 m-3 w-3 p-3 min-w-min surface-100">
                <form>
                    <div class="block font-bold text-center p-2">
                        <span>Recriar Senha</span>
                    </div>
                    <div class="block font-bold text-center p-2">
                        <span class="p-input-icon-right m-1">
                            <i [ngClass]="showPass ? 'pi pi-eye-slash' : 'pi pi-eye'" (click)="this.showPass = !this.showPass;"></i>
                            <input #txtNewPassword="ngModel" placeholder="Nova Senha" [type]="showPass ? 'text' : 'password'" pInputText [(ngModel)]="newPassword" name="newpassword" autofocus autocomplete="off"  (input)="passwordCorrect = txtNewPassword.value === txtConfirmPassword.value">
                        </span>
                        <span class="p-input-icon-right m-1">
                            <i [ngClass]="showPass ? 'pi pi-eye-slash' : 'pi pi-eye'" (click)="this.showPass = !this.showPass;"></i>
                            <input #txtConfirmPassword placeholder="Confirmar Nova Senha" [type]="showPass ? 'text' : 'password'" pInputText name="newpassword" autocomplete="off" (input)="passwordCorrect = txtNewPassword.value === txtConfirmPassword.value">
                        </span>
                    </div>
                    <div class="block font-bold text-center p-2">
                        <p-button (onClick)="resetPassword()" label="Alterar" styleClass="p-button-sm" [disabled]="!passwordCorrect"></p-button>
                    </div>
                </form>
            </div>
        </div>
        <div class="flex align-items-center justify-content-center h-3rem font-bold m-2">
            <img src="assets/imgs/logo_gtp.svg" class="h-full" alt="GTP Logo">
        </div>
    </div>
</div>