import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HunterProductModel } from "../../model/HunterProduct";
import { BaseService } from "./base.service";

@Injectable()
export class ProductModelService extends BaseService<HunterProductModel> {
    constructor(protected http: HttpClient) {
        super(http, "productmodel");
    }
}
