
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { AndroidInterface } from "../../shared/interface/AndroidInterface";
import { HunterPermission } from "../../shared/model/HunterPermission";
import { HunterUser } from "../../shared/model/HunterUser";
import { ResetPasswordForm } from "../interfaces/ResetPasswordForm";

declare let forge: any;

declare global {
    interface Window { AndroidInterface: any }
}

@Injectable()
export class AuthService {

    constructor(private http: HttpClient) {
        // no code
    }

    getCredential(username: String): Observable<any> {
        return this.http.get<any>(environment.coreserver + "auth/preauth/" + username);
    }

    login(username: string, password: string): Observable<any> {
        const credential = { credential: password };

        return this.http.post<any>(environment.coreserver + "auth/validate/" + username, credential);
    }

    deriveAKey(password: string, salt: string, iterations: number) {
        const byteSalt = forge.util.hexToBytes(salt);

        return forge.util.bytesToHex(
            forge.pkcs5.pbkdf2(password, byteSalt, iterations, 64)
        );
    }

    sha256(text: string) {
        const md = forge.md.sha256.create();

        md.update(text);
        return md.digest().getBytes();
    }

    encrypt(json: string): string {
        const key = this.sha256("globalsys123");
        const iv = forge.util.createBuffer(new Int8Array(16));
        const cipher = forge.cipher.createCipher("AES-CBC", key);

        cipher.start({ iv: iv });
        cipher.update(forge.util.createBuffer(json, "utf-8"));
        cipher.finish();
        const encrypted = cipher.output;
        return (forge.util.encode64(encrypted.getBytes()));
    }

    decrypt(encryptedEncodedMessage: string): string {
        const key = this.sha256("globalsys123");
        const iv = forge.util.createBuffer(new Int8Array(16));
        const msgStr = forge.util.decode64(encryptedEncodedMessage);
        const decipher = forge.cipher.createDecipher("AES-CBC", key);

        decipher.start({ iv: iv });
        decipher.update(forge.util.createBuffer(msgStr, "utf-8"));
        decipher.finish();
        return decipher.output.data;
    }

    getUser(): Observable<HunterUser> {
        return this.http.get<HunterUser>(environment.coreserver + "user/");
    }

    getPermissions(): Observable<HunterPermission[]> {
        return this.http.get<HunterPermission[]>(environment.coreserver + "user/permission/").pipe(
            map((perms: HunterPermission[]) => {
                if (perms != null) {
                    return perms
                        .filter((p) => p.app === "HunterWEB")
                        .filter((perm, i, arr) => {
                            const p = arr.find((t) => t.id === perm.id);
                            return p != null && arr.indexOf(p) === i;
                        });
                }
            }));
    }

    createPasswordReset(form: ResetPasswordForm): Observable<ResetPasswordForm> {
        return this.http.post<ResetPasswordForm>(`${environment.coreserver}auth/createResetPassword/${form.login}`, form);
    }

    resetPassword(form: ResetPasswordForm): Observable<ResetPasswordForm> {
        return this.http.post<ResetPasswordForm>(`${environment.coreserver}auth/resetPassword/${form.login}`, form);
    }

    checkMobile(): boolean {
        if (window.navigator.userAgent === "hunter WMS Mobile" || window.navigator.userAgent === "") {
            const android: AndroidInterface = window.AndroidInterface;
            const token: string = android.getToken();

            if (token != null && token.length > 0) {
                android.showToast(token);
                sessionStorage.setItem("currentUser", token);
                return true;
            }
        }
        return false;
    }
}
