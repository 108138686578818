import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HunterField } from "../../model/HunterField";
import { HunterDocumentField } from "../../model/HunterDocumentField";
import { BaseService } from "./base.service";

@Injectable()
export class DocumentFieldService extends BaseService<HunterDocumentField> {
    constructor(protected http: HttpClient) {
        super(http, "documentfield");
    }
}

@Injectable()
export class ProductFieldService extends BaseService<HunterField> {
    constructor(protected http: HttpClient) {
        super(http, "productfield");
    }
}

@Injectable()
export class PersonFieldService extends BaseService<HunterField> {
    constructor(protected http: HttpClient) {
        super(http, "personfield");
    }
}

@Injectable()
export class AddressFieldService extends BaseService<HunterField> {
    constructor(protected http: HttpClient) {
        super(http, "addressfield");
    }
}

@Injectable()
export class ThingFieldService extends BaseService<HunterField> {
    constructor(protected http: HttpClient) {
        super(http, "property");
    }
}
