import { AfterViewInit, Component, OnInit, Renderer2, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { MenuItem } from "primeng/api";
import { ScrollPanel } from "primeng/scrollpanel";
import { environment } from "../../../environments/environment";
import { HunterPermissionCategory } from "../../shared/model/HunterPermissionCategory";
import { HunterUUIDModel } from "../../shared/model/HunterUUIDModel";
import { NavigationService } from "../../shared/services/navigation.service";
import RestStatus from "../../shared/utils/restStatus";

@Component({
    selector: "permission",
    providers: [NavigationService],
    templateUrl: "menu.component.html",
    styleUrls: ["./menu.component.scss"]
})

export class MenuComponent implements OnInit, AfterViewInit {
    @ViewChild("pList", { static: false }) pList: ScrollPanel;
    items: MenuItem[];
    categories: HunterPermissionCategory[];
    envColor: string;
    mult: boolean;
    filter: string;
    constructor(private navSvc: NavigationService, private renderer: Renderer2, private route: Router) {
        this.envColor = environment.color != null ? environment.color : "";
    }

    ngOnInit() {
        this.categories = Array.of(...[]);
        this.navSvc.getItems().subscribe({
            next: (categories: HunterPermissionCategory[]) => {
                this.categories = categories;
                this.filterItems();
            },
            error: (error: RestStatus) => {
                console.log(error);
            }
        });
    }

    ngAfterViewInit() {
        this.renderer.setStyle(this.pList.contentViewChild.nativeElement, "background-color", this.envColor);
    }

    permSort = (a: HunterUUIDModel, b: HunterUUIDModel) => {
        if (a.name == null && b.name == null) {
            return 0;
        } else if (a.name == null) {
            return 1;
        } else if (b.name == null) {
            return -1;
        }
        return a.name.localeCompare(b.name);
    };

    filterItems() {
        this.mult = this.filter != null && this.filter !== "";
        this.items = Array.from([]);
        this.categories.sort(this.permSort);
        for (const c of this.categories) {
            c.permissions.sort(this.permSort);
            const links: MenuItem[] = Array.from([]);

            c.permissions
                .filter(p => this.showItem(p.name))
                .forEach(p => {
                    links.push({
                        label: p.name,
                        icon: "fa fa-" + p.icon,
                        automationId: p.id,
                        routerLink: p.route,
                        state: {
                            permission: p
                        },
                        routerLinkActiveOptions: { routerLinkActive: "active" },
                        command: () => {
                            this.filter = undefined;
                            this.filterItems();
                        }
                    });
                });
            if (links.length > 0) {
                this.items.push({
                    label: c.name,
                    icon: "fa fa-" + c.icon,
                    items: links,
                    expanded: (this.filter != null && this.filter.trim() !== "") || links.find(l => l.routerLink === this.route.url) != null
                });
            }
        }
    }

    showItem(pName: string) {
        if (this.filter == null || this.filter.trim() === "") {
            return true;
        }
        if (pName == null) {
            return false;
        }
        return pName.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1;
    }
}
