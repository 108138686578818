
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { TokenService } from "../services/token.service";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private tokenSvc: TokenService, private router: Router, private msgSvc: MessageService) {
        // no code
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.indexOf("/auth") === -1 || req.url.indexOf("/changePassword") >= 0) {
            const authHeader = "Bearer " + this.tokenSvc.getToken();
            const authReq = req.clone({ setHeaders: { Authorization: authHeader } });

            // console.log("ReqHeaders", authReq);
            return next.handle(authReq).pipe(
                catchError(x => this.handleAuthError(x))
            );
        } else {
            return next.handle(req);
        }
    }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        // handle your auth error or rethrow
        if (err.status === 401 || err.status === 403) {
            // navigate /delete cookies or whatever
            this.tokenSvc.logout();
            this.msgSvc.add({ severity: "error", summary: "Erro de Comunicação", detail: "Sua conexão com o servidor foi reiniciada. Retornando a tela de login..." });
            setTimeout(() => {
                this.router.navigate(["/security/login"]);
            }, 600);
            // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
            return of(err.message); // or EMPTY may be appropriate here
        }
        return throwError(() => err);
    }
}
