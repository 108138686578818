
import { HostListener, Injectable, OnDestroy } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { interval as observableInterval, Observable, Observer, Subscription } from "rxjs";
import { filter, map, share, takeWhile } from "rxjs/operators";

interface ConnectionStateChangedCallback {
    onConnect?: () => void;
    onDisconnect?: () => void;
}


@Injectable()
export class SocketService implements OnDestroy {
    private reconnectionObservable: Observable<any>;
    private socket: WebSocket;
    private reconnectInterval = 5000;  /// pause between connections
    private reconnectAttempts = 10000;  /// number of connection attempts
    private routerSubscription: Subscription;
    private reconnectSubscription: Subscription;
    private closeOnNavigationStart: boolean;

    public message$: Observable<any>;

    @HostListener("window:beforeunload", ["$event"])
    beforeUnloadHander(event) {
        // console.log("SocketService", "beforeUnload");
        this.unsubscribeObservables();
    }

    ngOnDestroy() {
        // console.log("SocketService", "OnDestroy");
        this.unsubscribeObservables();
    }

    constructor(private router: Router) {
        // console.log('RouterSubscription');
        this.routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (this.closeOnNavigationStart) {
                    this.disconnect("Service NavigationStart");
                }
            }
        });
    }

    public connect(url: string, connectionChanged?: ConnectionStateChangedCallback, closeOnNavigationStart: boolean = true): Observable<any> {
        this.closeOnNavigationStart = closeOnNavigationStart;
        this.socket = new WebSocket(url);

        this.socket.onopen = () => {
            // console.log(`WebSocket ${this.socket.readyState}: Connected to ${url}`);
            if (connectionChanged?.onConnect) {
                connectionChanged?.onConnect();
            }
        };

        this.socket.onclose = (e: CloseEvent) => {
            if (connectionChanged?.onDisconnect) {
                connectionChanged?.onDisconnect();
            }
            console.log(`Socket closed (${e.code}) ${e.reason}`);
            if (e.code === 4008) { // Back to Login
                this.refresh();
            } else if (e.code === 1008) { // Policy Violation
                if (this.isConnected()) {
                    this.socket.close(4000, "Vilated Policy, need to login to refresh token");
                } else {
                    this.refresh();
                }
            } else if ([1000, 1003, 1009].includes(e.code)) { // Won't Reconnect
                if (this.isConnected()) {
                    this.socket.close(1000, e.reason);
                }
            } else {
                // console.log('Reconnect will be attempted in 5 seconds.');
                this.reconnect(url);
            }
        };

        this.socket.onerror = (err: Event) => {
            if (this.socket.readyState !== WebSocket.CLOSING && this.socket.readyState !== WebSocket.CLOSED) {
                // console.error('Socket encountered error: ', err, 'Closing socket');
                this.reconnectSubscription?.unsubscribe();
                this.reconnectionObservable = null;
                this.socket.close(3000);
            }
        };

        return new Observable<any>((observer: Observer<any>) => {
            this.socket.onmessage = (evt) => {
                // console.log('OnMessage', evt);
                observer.next(evt);
            };
        }).pipe(
            filter((res: any) => {
                if (res.data === "PING") {
                    setTimeout(() => this.socket.send("PONG"), 5);
                    return false;
                } else if (res.data === "PONG") {
                    return false;
                }
                return true;
            }),
            map(res => JSON.parse(res.data)),
            share());
    }

    refresh(): void {
        if (sessionStorage.getItem("kiosk") === "true") {
            location.reload();
        } else {
            this.router.navigate(["security", "login"]);
        }
    }

    /// WebSocket Reconnect handling
    reconnect(url: string): void {
        console.log("Reconnect", url);
        if (this.reconnectSubscription != null) {
            this.reconnectSubscription.unsubscribe();
        }
        this.reconnectionObservable = observableInterval(this.reconnectInterval)
            .pipe(
                takeWhile((v, index) => index < this.reconnectAttempts && this.socket.readyState === WebSocket.CLOSED)
            );
        this.reconnectSubscription = this.reconnectionObservable.subscribe({
            next: () => {
                // console.log("Reconnect Next");
                this.connect(url);
            },
            error: (e) => {
                // console.log("Reconnect Error", e);
            },
            complete: () => {
                /// if the reconnection attempts are failed, then we call complete of our Subject and status
                // console.log("Reconnect Complete");
            }
        }
        );
    }

    /// sending the message
    public send(data: any): void {
        // console.log("WebSocket: SENDING MESSAGE", this.socket.readyState);
        if (typeof (data) === "string") {
            this.socket.send(data);
        } else {
            this.socket.send(JSON.stringify(data));
        }
    }

    private unsubscribeObservables(): void {
        this.routerSubscription?.unsubscribe();
        this.reconnectSubscription?.unsubscribe();
        this.disconnect("Service UnsubscribeObservables");
    }

    public disconnect(reason: string) {
        // console.log('Disconnect Called', reason);
        if (this.isConnected()) {
            this.socket.close(1000, reason);
        }
    }

    public isConnected(): boolean {
        // console.log("WebSocket: Connection status " + this.socket.readyState);
        return this.socket?.readyState === WebSocket.OPEN;
    }
}
