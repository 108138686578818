<div class="login">
    <div class="flex flex-column">
        <div class="flex align-items-center justify-content-center h-9rem font-bold m-2"></div>
        <div class="flex align-items-center justify-content-center h-3rem font-bold mt-2">
                <img src="assets/imgs/logo_hunter_horizontal.png" class="h-full" alt="Hunter Logo">
        </div>
        <div class="flex align-items-center justify-content-center h-1rem font-bold mb-2">
                <p class="text-black-alpha-20">Technology for Business Transformation</p>
        </div>
        <div class="flex align-items-center justify-content-center h-1rem font-bold m-2"></div>
        <div class="flex align-items-center justify-content-center h-auto font-bold m-2">
            <div class="box shadow-5 m-3 w-3 p-3 min-w-min surface-100">
                <form>
                    <div class="block font-bold text-center p-2">
                        <span *ngIf="!showUsernameInLabel">Entre com seu usuário</span>
                        <span *ngIf="showUsernameInLabel">Entre com sua senha</span>
                    </div>
                    <div class="block font-bold text-center p-2">
                        <span [ngStyle]="{'visibility': showUsernameInLabel ? 'visible' : 'hidden'}">&nbsp;{{username}}</span>
                    </div>
                    <div class="block font-bold text-center p-2">
                        <span *ngIf="!showUsernameInLabel"  class="p-input-icon-right">
                            <i class="pi pi-user"></i>
                            <input type="text" pInputText [(ngModel)]="username" autocomplete="off" name="user" placeholder="Nome de Usuário" class="user" autofocus>
                        </span>
                        <span *ngIf="showUsernameInLabel && credentials != null && credentials.type !== 'CredentialDevice'" class="p-input-icon-right">
                            <i [ngClass]="showPass ? 'pi pi-eye-slash' : 'pi pi-eye'" (click)="togglePassword()"></i>
                            <input placeholder="Senha" [type]="showPass ? 'text' : 'password'" pInputText [(ngModel)]="pwd" name="password" autofocus autocomplete="off">
                        </span> 
                    </div>
                    <div class="block text-center p2 h-2rem">
                        <p-button styleClass="p-button-link p-button-sm" label="Esqueci minha senha!" *ngIf="credentials?.type === 'CredentialPassword'" (onClick)="showResetPassword = !showResetPassword"></p-button>
                    </div>
                    <div class="block font-bold text-center p-2">
                        <button pButton *ngIf="credentials == null" type="submit" class="btn btn-sm enter" (click)="getCredential()">Entrar</button>
                        <button pButton *ngIf="credentials != null" type="submit" class="btn btn-sm enter" (click)="login()">Validar</button>
                    </div>
                </form>
                <!--<p id="labelForgotPassword">
                    <a href=""> <small>Forgot your password?</small></a> <br>
                    <a id="backToReportUser" *ngIf="showUsernameInLabel == true" (click)="backToLogin()"
                        style="cursor:pointer"><small>Change
                            user</small></a>
                </p>-->
            </div>
        </div>
        <div class="flex align-items-center justify-content-center h-3rem font-bold m-2">
            <img src="assets/imgs/logo_gtp.svg" class="h-full" alt="GTP Logo">
        </div>
        <div class="flex align-items-end justify-content-end font-bold m-2 login-version-info">
            <span class="text-xs">v{{version}}</span>
        </div>
    </div>
</div>
<p-dialog  header="Alterar Senha" [(visible)]="showResetPassword" [style]="{width: '25vw'}" (onShow)="checkFormExpired()">
    <div *ngIf="resetPasswordContent == null">
        <p class="m-1">Caso o e-mail seja de uma conta existente será enviado um link para que seja alterada a senha do usuário.</p>
        <span class="p-input-icon-left w-full">
            <i class="pi pi-envelope"></i>
            <input #txtMail="ngModel" [(ngModel)]="resetEmail" pInputText type="email" class="p-inputtext-sm w-full" name="email" aria-describedby="mail-help" email="true" required pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" autofocus/>
        </span>
        <small id="mail-help" class="p-error block" *ngIf="txtMail.errors?.email">E-mail inválido.</small>
        <small id="mail-help" class="p-error block" *ngIf="txtMail.errors?.required">Preencha o e-mail.</small>
    </div>
    <div class="white-space-normal">
        {{resetPasswordContent}}
    </div>
    <ng-template pTemplate="footer">
        <p-button (onClick)="createResetPasswordForm()" label="Confirmar" styleClass="p-button-text p-button-sm" *ngIf="resetPasswordContent == null"></p-button>
        <p-button (onClick)="showResetPassword = false" label="Fechar" styleClass="p-button-text p-button-sm "*ngIf="resetPasswordContent != null"></p-button>
    </ng-template>
</p-dialog>