import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, of } from "rxjs";
import { environment } from "../../../environments/environment";
import RestStatus from "../utils/restStatus";

@Injectable()
export class FileService {
    protected readonly baseURL: string;

    constructor(protected http: HttpClient) {
        this.baseURL = `${environment.coreserver}file`;
    }

    download(path: string, fileName: string): Observable<any> {
        return this.http.get(`${this.baseURL}/download/${path.replace(/^\/+|\/+$/g, "")}/${fileName.replace(/^\/+|\/+$/g, "")}`, { responseType: "blob" })
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    console.log("Error", error);
                    return new Observable<any>();
                }));
    }

    upload(url: string, form: FormData): Observable<RestStatus> {
        return this.http.post<RestStatus>(url, form)
            .pipe(
                catchError(error => {
                    const errStatus = new RestStatus(false, error.error);

                    console.log("Error", error);
                    return of(errStatus);
                })
            );
    }
}

