
import { empty as observableEmpty } from "rxjs";

import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { catchError } from "rxjs/operators";
import { HunterUser } from "../../shared/model/HunterUser";
import { AuthService } from "../services/auth.service";
import { TokenService } from "../services/token.service";

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private authSvc: AuthService, private tokenSvc: TokenService) {
        // no code
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!route.queryParams["t"] && (sessionStorage.getItem("currentUser") || this.authSvc.checkMobile())) {
            // console.log('Guard SesionStorage');
            // if (state.url == '/home' || state.url == '/security/login' || state.url == '/home/process/action' || state.url == '/home/core/deviceList' || state.url == '/home/core/listDocuments' ||
            //     state.url == '/home/process/featureList' || state.url == '/home/core/groupList' || state.url == '/home/core/location'
            //     || state.url == '/home/process/originList' || state.url == '/home/core/personList'
            //     || state.url == '/home/core/permissionList' || state.url == '/home/core/portList' || state.url == '/home/process/processFilter'
            //     || state.url == '/home/process/process' || state.url == '/home/process/productModel' || state.url == '/home/process/propertyModel'
            //     || state.url == '/home/process/purposeList' || state.url == '/home/core/sourceList' || state.url == '/home/core/leaflet' || state.url == '/home/gis/leafletGis'
            //     || state.url == '/home/process/taskDef' || state.url == '/home/process/workflow') {
            //     // logged in so return true
            //     //rotas permitidas
            return true;
            // } else {
            //     alert('Acesso negado!');
            //     return false; //se a rota selecionada não estiver entre as permitidas, o usuario nao consegue acessar a rota
            // }
        } else if (route.queryParams["t"]) {
            // console.log('Guard TokenQuery');
            this.tokenSvc.setToken(route.queryParams["t"]);
            this.authSvc.getUser().pipe(
                catchError((err: HttpErrorResponse) => {
                    console.log("Guard Error", err);
                    this.unauthorized(state);
                    return observableEmpty();
                })
            ).subscribe({
                next: (data: HunterUser) => {
                    this.tokenSvc.setUid(data.id);
                    this.tokenSvc.setKiosk("true");
                    // console.log('Guard Token OK', this.tokenSvc.kiosk);
                }, 
                error: error => {
                console.log("Guard Sub Error", error);
                this.unauthorized(state);
            }
            });
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.unauthorized(state);
        return false;
    }

    unauthorized(state: RouterStateSnapshot) {
        this.tokenSvc.logout();
        this.router.navigate(["/security/login"], { queryParams: { returnUrl: state.url } });
    }
}
