<div class="flex flex-column align-items-start justify-content-start w-full">
    <div class="flex flex-row align-items-start justify-content-start w-full">
        <span class="p-input-icon-left w-full">
            <i class="pi pi-filter"></i>
            <input type="text" [(ngModel)]="filter" pInputText placeholder="Filtrar" (keyup)="filterItems();" class="w-full p-inputtext-sm">
        </span>
    </div>
    <div class="flex flex-row align-items-start justify-content-start w-full">
        <p-divider align="center" type="dashed" class="w-full">
            <small class="text-xs">Permissões</small>
        </p-divider>
    </div>
    <div class="flex flex-row align-items-start justify-content-start w-full">
        <p-scrollPanel #pList styleClass="custombar" class="h-full w-full">
            <p-panelMenu [model]="items" [multiple]="mult"></p-panelMenu>
        </p-scrollPanel>
    </div>
</div>