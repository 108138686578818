import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { MessageService } from "primeng/api";
import { InternalSharedModule } from "../shared/shared.module";
import { LoginComponent } from "./components/login.component";
import { AuthGuard } from "./guards/auth.guard";
import { AuthInterceptor } from "./interceptors/auth.interceptor";
import { LogInterceptor } from "./interceptors/log.interceptor";
import { SecurityRoutingModule } from "./security-routing.module";
import { AuthService } from "./services/auth.service";
import { TokenService } from "./services/token.service";
import { ResetPasswordComponent } from './components//reset-password.component';

@NgModule({
    imports: [InternalSharedModule, SecurityRoutingModule, HttpClientModule],
    declarations: [LoginComponent, ResetPasswordComponent],
    providers: [AuthGuard, AuthService, TokenService, MessageService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LogInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
    ],
    exports: []
})
export class SecurityModule { }
