import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HunterPerson } from "../../model/HunterPerson";
import { BaseService } from "./base.service";

@Injectable()
export class PersonService extends BaseService<HunterPerson> {
    constructor(protected http: HttpClient) {
        super(http, "person");
    }
}
