import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { HunterThing } from "../../model/HunterThing";
import { BaseService } from "./base.service";

@Injectable()
export class ThingService extends BaseService<HunterThing> {
    constructor(protected http: HttpClient) {
        super(http, "thing");
    }

    getByTagId(tagId: string): Observable<HunterThing> {
        return this.http.get<HunterThing>(`${this.baseURL}/bytagid/${tagId}`)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    console.log("Error", error);
                    return new Observable<HunterThing>();
                }));
    }

    fillUnits(t: HunterThing): Observable<HunterThing> {
        return this.http.get<HunterThing>(`${this.baseURL}/fillunits/${t.id}`)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    console.log("Error", error);
                    return of(t);
                }));
    }
}
