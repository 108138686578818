import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HunterGroup } from "../../model/HunterGroup";
import { BaseService } from "./base.service";

@Injectable()
export class GroupService extends BaseService<HunterGroup> {
    constructor(protected http: HttpClient) {
        super(http, "group");
    }
}
