import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HunterPermission } from "../../model/HunterPermission";
import { BaseService } from "./base.service";

@Injectable()
export class PermissionService extends BaseService<HunterPermission> {
    constructor(protected http: HttpClient) {
        super(http, "permission");
    }
}
