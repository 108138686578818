import { HostListener, Injectable, OnDestroy } from "@angular/core";


@Injectable()
export class TokenService implements OnDestroy {

    public kiosk: boolean;

    logout() {
        // console.log('Logging Out');
        sessionStorage.removeItem("currentUser");
        sessionStorage.removeItem("salt");
        sessionStorage.removeItem("uid");
        sessionStorage.removeItem("kiosk");
    }

    setToken(token: string) {
        sessionStorage.setItem("currentUser", token);
    }

    setSalt(salt: string) {
        sessionStorage.setItem("salt", salt);
    }

    setUid(uid: string) {
        sessionStorage.setItem("uid", uid);
    }

    setKiosk(kiosk: string) {
        sessionStorage.setItem("kiosk", kiosk);
        this.kiosk = kiosk === "true";
    }

    getToken() {
        return sessionStorage.getItem("currentUser");
    }

    getSalt() {
        return sessionStorage.getItem("salt");
    }

    getSession() {
        return sessionStorage.getItem("session");
    }

    getUid() {
        return sessionStorage.getItem("uid");
    }

    ngOnDestroy() {
        console.log("token destroy");
    }

    @HostListener("window:beforeunload", ["$event"])
    beforeUnloadHander(event) {
        console.log("Token Unload");
    }
}
