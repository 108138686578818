import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { HunterAddress } from "../../model/HunterAddress";
import { BaseService } from "./base.service";

@Injectable()
export class AddressService extends BaseService<HunterAddress> {

    constructor(protected http: HttpClient) {
        super(http, "address");
    }

    listByTypeAndLocation(type: string, location: string): Observable<HunterAddress[]> {
        return this.http.get<HunterAddress[]>(`${this.baseURL}/bytypeandlocation/${type}/${location}`)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    console.log("Error", error);
                    return new Observable<HunterAddress[]>();
                }));
    }
}
