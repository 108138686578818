import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { HunterProduct } from "../../model/HunterProduct";
import { BaseService } from "./base.service";

@Injectable()
export class ProductService extends BaseService<HunterProduct> {

    constructor(protected http: HttpClient) {
        super(http, "product");
    }

    listByTypeAndSiblings(type: string): Observable<HunterProduct[]> {
        return this.http.get<HunterProduct[]>(`${this.baseURL}/bytypeandsiblings/${type}`)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    console.log("Error", error);
                    return new Observable<HunterProduct[]>();
                }));
    }

    getBySku(sku: string): Observable<HunterProduct> {
        return this.http.get<HunterProduct>(`${this.baseURL}/bysku/${{ sku }}`)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    console.log("Error", error);
                    return new Observable<HunterProduct>();
                }));
    }
}
