import { ErrorHandler, Inject, Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { StatusCodes } from "http-status-codes";
import { MessageService } from "primeng/api";

const REFRESH_PAGE_ON_TOAST_CLICK_MESSAGE = "Ocorreu um erro inesperado: ";
const DEFAULT_ERROR_TITLE = "ERRO";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(@Inject(MessageService) private msgSvc: MessageService, @Inject(Injector) private injector: Injector) {
        // empty
    }

    handleError(error: any) {
        const router = this.injector.get(Router);
        const message = error.message ? error.message : error.toString();
        const httpErrorCode = error.httpErrorCode;

        switch (httpErrorCode) {
            case StatusCodes.UNAUTHORIZED:
                router.navigate(["/security/login"]);
                break;
            case StatusCodes.FORBIDDEN:
                router.navigate(["/security/login"]);
                break;
            case StatusCodes.BAD_REQUEST:
                this.showError(message);
                break;
            default:
                this.showError(message);
        }
        throw error;
    }

    private showError(message: string) {
        this.msgSvc.add({ severity: "error", summary: DEFAULT_ERROR_TITLE, detail: REFRESH_PAGE_ON_TOAST_CLICK_MESSAGE + message });
    }
}
