import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HunterOrigin } from "../../model/HunterOrigin";
import { BaseService } from "./base.service";

@Injectable()
export class OriginService extends BaseService<HunterOrigin> {
    constructor(protected http: HttpClient) {
        super(http, "origin");
    }
}
