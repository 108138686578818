
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../security/services/auth.service";
import { HunterPermission } from "../model/HunterPermission";
import { HunterPermissionCategory } from "../model/HunterPermissionCategory";

@Injectable()
export class NavigationService {

    constructor(private authSvc: AuthService) {
        // empty
    }

    getItems(): Observable<HunterPermissionCategory[]> {
        return this.authSvc.getPermissions().pipe(
            map((perms: HunterPermission[]) => {
                const categories: HunterPermissionCategory[] = Array.from([]);

                if (perms != null) {
                    for (const p of perms) {
                        const cat = categories.find(c => c.id === p.category.id);

                        if (cat == null) {
                            const c = p.category;
                            c.permissions = Array.from([]);
                            c.permissions.push(p);
                            categories.push(c);
                        } else {
                            cat.permissions.push(p);
                        }
                    }
                }
                return categories;
            }));
    }
}
