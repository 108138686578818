import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { HunterDocument } from "../../model/HunterDocument";
import { HunterDocumentThing } from "../../model/HunterDocumentThing";
import { BaseService } from "./base.service";

@Injectable()
export class DocumentService extends BaseService<HunterDocument> {
    constructor(protected http: HttpClient) {
        super(http, "document");
    }

    listByTypeCreatedDate(type: string, createdDate: string): Observable<HunterDocument[]> {
        return this.http.get<HunterDocument[]>(`${this.baseURL}/byTypeCreatedDate/${type}/${createdDate}`)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    console.log("Error", error);
                    return new Observable<HunterDocument[]>();
                }));
    }

    quickByTypeAndCode(type: string, code: string): Observable<HunterDocument> {
        return this.http.get<HunterDocument>(`${this.baseURL}/quickByTypeAndCode/${type}/${code}`)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    console.log("Error", error);
                    return new Observable<HunterDocument>();
                }));
    }
}

@Injectable()
export class DocumentThingService extends BaseService<HunterDocumentThing> {
    constructor(protected http: HttpClient) {
        super(http, "documentthing");
    }
}
