<div class="row" *ngIf="items == null || items.length == 0">
  <div class="col">
    <div class="logo">
      <img src="assets/imgs/logo_hunter.png" alt="Hunter logo" width="48px" length="48px">
      <div class="text-center text-xs text-primary w-full cursor-pointer" (click)="changePassword()">Alterar Senha</div>
    </div>
  </div>
  <div class="col">
    <p class="text-center text-4xl text-secondary w-full" style="line-height:50vh">Usuário sem permissões!</p>
  </div>
</div>
<p-dock [model]="items" position="top" [ngClass]="'dashboard-top-docker'">
  <ng-template pTemplate="item" let-item>
    <i class="text-2xl {{item.icon}}"></i>
    <span class="text-xs text-overflow-ellipsis">{{item.label}}</span>
  </ng-template>
</p-dock>
<div class="dashboard-main">
    <div class="flex justify-content-evenly flex-wrap">
        <div class="flex align-items-center justify-content-center w-2 border-round m-2 card-container" 
            *ngFor="let item of subitems; let i = index"
            routerLink="{{item.routerLink}}" routerLinkActive="active">
          <div pRipple class="box shadow-5 m-3 min-w-min w-full styled-box-blue">
            <div class="block font-bold text-center p-3 w-full">
              <i class="text-4xl {{item.icon}}"></i>
            </div>
            <div class="block font-bold text-center p-3 w-full">
              <span class="text-lg">{{item.label}}</span>
            </div>
          </div>
        </div>
    </div>
</div>