import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MenuItem } from "primeng/api";
import { TokenService } from "../../security/services/token.service";
import { HunterPermission } from "../../shared/model/HunterPermission";
import { HunterPermissionCategory } from "../../shared/model/HunterPermissionCategory";
import { HunterUUIDModel } from "../../shared/model/HunterUUIDModel";
import { NavigationService } from "../../shared/services/navigation.service";
import RestStatus from "../../shared/utils/restStatus";


@Component({
    selector: "app-dashboard",
    providers: [NavigationService],
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit {
    items: MenuItem[];
    subitems: MenuItem[];

    constructor(private navSvc: NavigationService, private tokenSvc: TokenService, private router: Router) {
        // no code
    }

    ngOnInit(): void {
        this.items = Array.from([]);
        this.navSvc.getItems()
            .subscribe({
                next: (categories: HunterPermissionCategory[]) => {
                    this.items = categories.sort(this.sortByName)
                        .map(c => ({
                            label: c.name,
                            icon: "fa fa-" + c.icon,
                            command: () => {
                                this.showMenu(c);
                            }
                        }));
                    if (categories.length > 0) {
                        this.showMenu(categories.sort(this.sortByName)[0]);
                    }
                },
                error: (error: RestStatus) => {
                    console.log(error);
                }
            });
    };

    changePassword(): void {
        this.router.navigate(["/home/core/changePassword/" + this.tokenSvc.getUid()]);
    };

    showMenu(c: HunterPermissionCategory) {
        this.subitems = c.permissions
            .sort(this.sortByName)
            .map((p: HunterPermission) => ({
                label: p.name,
                icon: "fa fa-" + p.icon,
                automationId: p.id,
                routerLink: p.route,
                routerLinkActiveOptions: { routerLinkActive: "active" },
            }));
    }

    sortByName = (a: HunterUUIDModel, b: HunterUUIDModel) => {
        if (a == null && b == null) {
            return 0;
        }
        if (a == null) {
            return 1;
        }
        if (b == null) {
            return -1;
        }
        if (a.name == null && b.name == null) {
            return 0;
        }
        if (a.name == null) {
            return 1;
        }
        if (b.name == null) {
            return -1;
        }
        return a.name.localeCompare(b.name);
    };
}
